import React from "react";
import Nav from "../Nav/Nav";
import styled from "styled-components";
import Footer from "../footer/Footer";

import { useLocation } from "react-router-dom";

const Faq = () => {
  const { pathname } = useLocation();

  if (pathname === "/Faq") {
    document.title = "Faq | The Mary Babatola Bifarin Grant";
  }

  return (
    <>
      <Nav />
      <FaqHero>
        <div className="hero">
          <div className="hero-header">
            <h1>FAQ</h1>
          </div>
          <div className="hr-sub-header">
            <p>
              MBB microgrant program will give at least N500,000  grant
              to <span>at least</span> two teams / individuals in Nigeria to do
              something impactful.
            </p>
          </div>
        </div>
      </FaqHero>

      <Questions>
        <div className="faqs">
          <div className="question-ans">
            <div className="question">
              <h4>Are specific academic qualifications required ?</h4>
            </div>
            <div className="answer">
            Academic qualifications are not required. However,
             if you do have relevant academic qualifications for your project, 
             be sure to state that in your application.
              
            </div>
          </div>
          <div className="question-ans">
            <div className="question">
              <h4>Will the winning projects be made public?</h4>
            </div>
            <div className="answer">
              <p>
                Yes. For transparency purposes and to magnify the positive
                impact.
              </p>
            </div>
          </div>
          <div className="question-ans">
            <div className="question">
              <h4>How do I know the outcome of my application?</h4>
            </div>
            <div className="answer">
              <p>Only successful grant applicants will be contacted.</p>
            </div>
          </div>
          <div className="question-ans">
            <div className="question">
              <h4>
              What does your ideal winning team/individual/project look like (especially for the
science and tech Track)?
              </h4>
            </div>
            <div className="answer">
              <p>
              If it’s a team, it will be ideal if it’s multidisciplinary. You could also be a lone wolf. Fine with me. 
              The (potential) impact of your project is the most critical factor. 
              </p>
            </div>
          </div>
          <div className="question-ans">
            <div className="question">
              <h4>
                My project is a little complicated, and I will need more that
                N500,000?
              </h4>
            </div>
            <div className="answer">
              <p>
              Submit your application. Justify why you need more. 
              </p>
            </div>
          </div>
          <div className="question-ans">
            <div className="question">
              <h4>What is the age limit?</h4>
            </div>
            <div className="answer">
              <p>Preference will go to the applicants who are 35 years old or younger.</p>
            </div>
          </div>
          <div className="question-ans">
            <div className="question">
              <h4>How will I get the money?</h4>
            </div>
            <div className="answer">
              <p>
                Ideally in two or more chunks (directly in your bank accounts or
                via Paypal), you will need to give project update to initiate
                each round of collection
              </p>
            </div>
          </div>
          <div className="question-ans">
            <div className="question">
              <h4>I have additional questions, how do I contact you?</h4>
            </div>
            <div className="answer">
              <p>
                Send an e-mail to <span>mbbifaringrant@gmail.com</span>
              </p>
            </div>
          </div>
          <div className="question-ans">
            <div className="question">
              <h4>How can I get updates about this project?</h4>
            </div>
            <div className="answer">
              <p>
                Subscribe to the grant newsletter at{" "}
                <span><a
              href="https://mbbgrant.substack.com/"
              target="_blank"
              rel="noreferrer"
              
            >
              mbbgrant.substack.com.
            </a></span>{" "}Follow our{" "}
                <span><a
              href="https://www.instagram.com/mbb.grant/"
              target="_blank"
              rel="noreferrer"
              
            >
              Instagram
            </a></span>
                I will share the updates on these Platforms
              </p>
            </div>
          </div>
          <div className="question-ans">
            <div className="question">
              <h4>What is the time frame for the project completion?</h4>
            </div>
            <div className="answer">
              <p>
                None. However, preference will be given to shorter projects
                (without sacrificing potentially impactful ideas)
              </p>
            </div>
          </div>
        </div>
      </Questions>
      <Footer />
    </>
  );
};

const FaqHero = styled.section`
  background: #121212;

  padding: 11rem 4rem 4rem 4rem;
  .hero {
    max-width: 45rem;
  }
  .hr-sub-header {
    padding-top: 3rem;
    p {
      line-height: 1.6;
    }
    span {
      color: #ffe066;
    }
  }
  @media screen and (max-width: 765px) {
    padding: 9rem 1rem 4rem 1rem;
  }
`;

const Questions = styled.section`
  background: #f3f3f3;
  padding: 5rem 4rem 0 4rem;
  color: #121212;
  .faqs {
    display: grid;
    grid-template-columns: repeat(2, minmax(300px, 1fr));
    h4 {
      color: #313131;
    }
    span {
      color: #7d82b8;
      font-weight: 600;
      a {
        color: #7d82b8;
        text-decoration: none;
        font-weight: 600;
      }
    }
    .question-ans {
      padding: 1.5rem 1.5rem 1.5rem 0;
      border-bottom: 2px solid #000000;
      margin: 1rem 0 1.5rem 1rem;
      .question {
        h4 {
          padding-bottom: 1rem;
        }
      }
      .answer {
        p {
          line-height: 1.6;
          color: #2b2b2b;
        }
        a {
          text-decoration: none;
          color: #000000;
        }
      }
    }
  }
  @media screen and (max-width: 765px) {
    padding: 4rem 0;
    .faqs {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      margin: 0 1rem;
      .question-ans {
        margin: 1rem 0;
        padding: 1rem 0;
      }
    }
  }
`;
export default Faq;
