import React from "react";
import Nav from "../Nav/Nav";
import Footer from "../footer/Footer";
import styled from "styled-components";

import winner1 from "../images/winners-one.jpeg";
import winner2 from "../images/winners-two.jpeg";
import winner3 from "../images/winners-three.jpeg";
import winner4 from "../images/winners-four.jpeg";
import cohort2 from "../images/cohort2.jpg";
import patricia from "../images/patricia.jpg";

import cooker from "../images/cooker.jpg";


import genotype from "../images/genotype.jpg";

const Winners = () => {
  return (
    <>
      <Nav />
      <WinnersContainer>
        <MbbWinners>
          <div className="winners-header">
            <h1>MBB Grantees: Cohort 1</h1>
            <p>
              Cancer Patient’s Care, Giving 2.0, and Digitizing Local Markets
            </p>
         
          </div>
          <div className="video-container">
          <iframe 
          className="responsive-iframe"
          width="720" 
          height="315"
src="https://www.youtube.com/embed/g-nMyPv87Qg"
          title="Documentary Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen>
</iframe>
          </div>
          <div className="contents">
            <div className="img-container">
              <a href={winner1}>
                <img src={winner1} alt="winners" />
              </a>
            </div>
            
          </div>
          <div className="contents">
            <div className="number">
              <h1>1.</h1>
            </div>
            <div className="img-container">
              <a href={winner2}>
                <img src={winner2} alt="winners" />
              </a>
            </div>
            
          </div>
          <div className="contents">
            <div className="number">
              <h1>2.</h1>
            </div>
            <div className="img-container">
              <a href={winner3}>
                <img src={winner3} alt="winners" />
              </a>
            </div>
            
          </div>
          <div className="contents">
            <div className="number">
              <h1>3.</h1>
            </div>
            <div className="img-container">
              <a href={winner4}>
                <img src={winner4} alt="winners" />
              </a>
            </div>
            
          </div>
          <p>
                For more details about the winners check {" "}
                <span><a
              href="https://bifarinthefifth.substack.com/p/mbb-grant-winners"
              target="_blank"
              rel="noreferrer"
              
            >
              here.
            </a></span></p>
          <div className="winners-header">
            <h1>MBB Grantees: Cohort 2</h1>
            <p>
            Teaching Literacy to IDPs, Solar Cookers, and Sickle Cell Warriors. 
            </p>
            </div>
            <div className="video-container">
          <iframe 
          className="responsive-iframe"
          width="720" 
          height="315"
src="https://www.youtube.com/embed/oIQ_pRcyltQ"
          title="Documentary Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen>
</iframe>
          </div>
             <div className="img-container">
              <a href={winner2}>
                <img src={cohort2} alt="winners" />
              </a>
            </div>
            
         
            <div className="contents">
            <div className="number">
              <h1>1.</h1>
            </div>
            <div className="img-container">
              <a href={patricia}>
                <img src={patricia} alt="winners" />
              </a>
            </div>
            <p> </p>
            
            <p> </p>
            <div className="video-container">
          <iframe 
          className="responsive-iframe"
          width="720" 
          height="315"
src="https://www.youtube.com/embed/-Ick5WwSNOM"
          title="Documentary Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen>
</iframe>
          </div>
          <p> </p>
          <div className="number">
              <h1>2.</h1>
            </div>
            <div className="img-container">
              <a href={cooker}>
                <img src={cooker} alt="winners" />
              </a>
            </div>
            <p> </p>
            
            <p> </p>
          <div className="number">
              <h1>3.</h1>
            </div>
            <div className="img-container">
              <a href={genotype}>
                <img src={genotype} alt="winners" />
              </a>
            </div>
            <p> </p>
            
            <p> </p>
            <p>
                For more details about the winners check {" "}
                <span><a
              href="https://bifarinthefifth.substack.com/p/mbb-grantees-cohort-2"
              target="_blank"
              rel="noreferrer"
              
            >
              here.
            </a></span></p>
          </div>
        </MbbWinners>
       
      </WinnersContainer>
      <Footer />
    </>
  );
};

const WinnersContainer = styled.div`
  max-width: 45rem;
  color: #313131;
  margin: 0 auto;
  h4,
  h3 {
    color: #313131;
    line-height: 1.6;
  }
  p {
    line-height: 1.6;
  }
  .winners-header {
    h1 {
      color: #313131;
      font-weight: 800;
    }
  }
  .contents {
    padding: 1.5rem 0;
    .number {
      h1 {
        color: #313131;
        font-weight: 800;
      }
    }
    .content-txt {
      p {
        padding: 1rem 0 0 0;

        a {
          color: rgb(49, 49, 49);
          font-weight: 600;
        }
      }
      .video-container {
        position: relative;
        overflow: hidden;
        width: 100%
        padding-top: 56.25%;
        .responsive-iframe {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          
        }

      }
      .txt {
        padding: 0.6rem 0;
        .quote {
          border-left: 4px solid #ecded4;

          p {
            padding-left: 1rem;
          }
        }
      }
      .sub-header {
        padding: 1rem 0 0 0;
        h4 {
          font-weight: 600;
        }
        span {
          font-size: 1.3rem;
          line-height: 1.4;
          a {
            color: #313131;
          }
          .sub-title {
            font-weight: 600;
          }
        }
      }
    }
  }
  @media screen and (max-width: 765px) {
    padding: 1rem;
    h4 {
      font-size: 1.2rem;
    }
  }
`;
const MbbWinners = styled.div`
  padding: 10rem 0 0 0;
`;


export default Winners;
